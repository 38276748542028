import { useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import classes from "./TimeComponent.module.css";
import { TextEnum } from "../../SolarGikLib/TextStyles";
import { RootState } from "../app/Store";
import { useInterval } from "../../common/Hooks/useInterval";

function formatDateTime(timezone: string) {
  const siteTimeNow = dayjs().tz(timezone);
  return {
    date: siteTimeNow.format("ddd DD MMM"),
    time: siteTimeNow.format("HH:mm"),
  };
}

const TimeComponent = () => {
  const siteTimezone = useSelector((state: RootState) => state.site.ianaTimeZoneName);
  const [value, setValue] = useState(() => formatDateTime(siteTimezone));
  useInterval(() => setValue(formatDateTime(siteTimezone)), 1000);
  return (
    <div className={`${classes["clock-group"]} ${TextEnum.h4}`}>
      <div>{value.date}</div>
      <div className={classes["time"]}>{value.time}</div>
    </div>
  );
};
export default TimeComponent;
