// src/UnityManager.ts

import {IProgressHandler} from "../global";

export interface IUnityLoadingProvider {
    onLoadProgress: IProgressHandler | null;
    solarSightUnityPrefixPath: string;
    getSolarSightCanvas: () => HTMLCanvasElement;
}

export interface UnityInstance {
    canvas: HTMLCanvasElement;
    SendMessage: (gameObject: string, methodName: string, parameter: string | undefined) => void;
    Quit: () => void;
}

export type OnCleanUpDelegate = () => void;

export default class UnityManager {
    #unityLoadingHandler: IUnityLoadingProvider;
    #unityInstance: UnityInstance | null = null;
    #onCleanUp: OnCleanUpDelegate[] = [];

    constructor(unityLoadingHandler: IUnityLoadingProvider) {
        this.#unityLoadingHandler = unityLoadingHandler;
    }

    public async initUnityAsync(config: any): Promise<UnityInstance | null> {
        const onLoadProgress: IProgressHandler = this.#unityLoadingHandler.onLoadProgress || ((fraction) => {
            console.log(`Loading progress: ${(fraction * 100).toFixed(2)}%`);
        });

        const canvas = this.#unityLoadingHandler.getSolarSightCanvas();
        if (!canvas) {
            const errorMsg = "Unity canvas element not found.";
            console.error(errorMsg);
            throw new Error(errorMsg);
        }

        await createUnityInstance(canvas, config, onLoadProgress)
            .then((unityInstance) => {
                this.#unityInstance = unityInstance;
                this.#unityInstance!.canvas = canvas;
                this.setupCleanupObserver();
                console.debug("SolarSight: Unity instance created successfully.");
            })
            .catch((errMsg) => {
                throw new Error("SolarSight: Failed to create Unity instance:\n"+errMsg);
            });
        
        return this.#unityInstance;
    }

    public addCleanUpDelegate(delegate: OnCleanUpDelegate): void {
        this.#onCleanUp.push(delegate);
    }

    public removeCleanupDelegate(delegate: OnCleanUpDelegate): void {
        this.#onCleanUp = this.#onCleanUp.filter(l => l !== delegate);
    }

    public cleanup(): void {
        if (this.#unityInstance) {
            this.#unityInstance.Quit();
            this.#unityInstance = null;
        }

        this.#onCleanUp.forEach(delegate => delegate());
        this.#onCleanUp = [];
    }

    private setupCleanupObserver(): void {
        if (!this.#unityInstance) return;

        const parent = this.#unityInstance.canvas.parentNode;
        if (!parent) {
            console.warn("Canvas has no parent node to observe for removal.");
            return;
        }
    }
}