import { FC } from "react";
import { useSelector } from "react-redux";
import classes from "./SiteNameComponent.module.css";
import { RootState } from "../app/Store";
import { UserAccessType } from "../user/UserStore";
import { TextEnum } from "../../SolarGikLib/TextStyles";
import LightTooltipOnEllipsis from "../../SolarGikLib/tooltip/LightTooltipOnEllipsis";

interface ISiteNameComponentProps {
  siteId: string;
  asTitle?: boolean;
}

const SiteNameComponent: FC<ISiteNameComponentProps> = ({ siteId, asTitle = false }) => {
  const siteDisplayName = useSelector(
    (state: RootState) => state.multiSitesMetadata.sites[siteId]?.visualInfo.displayName
  );
  const isEngineer = useSelector((state: RootState) => state.user.userType >= UserAccessType.Engineer);
  const defaultName = siteDisplayName || siteId;
  const displayTwoNames = isEngineer && siteDisplayName;
  if (asTitle) {
    return (
      <LightTooltipOnEllipsis title={defaultName} customTooltipSx={{ fontSize: 14 }}>
        <div>
          {displayTwoNames && (
            <div
              className={`${TextEnum.h7} ${classes["surround-with-parenthesis"]} ${classes["site-name-with-ellipsis"]}`}
            >
              {siteId}
            </div>
          )}
          <div className={classes["site-name-with-ellipsis"]}>{defaultName}</div>
        </div>
      </LightTooltipOnEllipsis>
    );
  }

  return (
    <div>
      {displayTwoNames && (
        <div className={`${TextEnum.h7} ${classes["surround-with-parenthesis"]}`}>{siteId}</div>
      )}
      <div>{defaultName}</div>
    </div>
  );
};

export type SiteNameFormatterFunc = (siteId: string) => string;

export function useSiteNamesFormatter(): SiteNameFormatterFunc {
  const multiSitesMetadata = useSelector((state: RootState) => state.multiSitesMetadata);
  const isEngineer = useSelector((state: RootState) => state.user.userType >= UserAccessType.Engineer);
  return (siteId: string) => {
    if (!siteId) {
      return "";
    }
    const siteDisplayName = multiSitesMetadata.sites[siteId]?.visualInfo.displayName;
    if (isEngineer && siteDisplayName) {
      return `${siteId} (${siteDisplayName})`;
    }
    return siteDisplayName || siteId;
  };
}

export default SiteNameComponent;
