import { FC, useState } from "react";
import classes from "./SystemStatus.module.css";
import { convertValueToFieldState, isFieldStateBlinking } from "../../app/TagsToEnumTable";
import FieldStateDataPoint from "../../banner/FieldStateDataPoint";
import SystemStatusIcons from "../../banner/SystemStatusIcons";
import TagsNames from "../../data_point/TagsNames";
import { AuxiliariesStatusIcons } from "../../../SolarGikLib/icons/IconsModels";
import SafeOverrideTooltip from "./SafeOverrideTooltip";
import { useSystemStatusDescription } from "../../system_status/useSystemStatusDescription";

interface ISystemStatusProps {
  siteId: string;
}
const SystemStatus: FC<ISystemStatusProps> = ({ siteId }) => {
  const { invertersIssusDescription, trackersIssuesDescription } = useSystemStatusDescription(siteId);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  return (
    <div className={classes.row}>
      <div className={classes["field-state-container"]}>
        <FieldStateDataPoint
          tagName={TagsNames.MCS_FIELD_STATE}
          siteId={siteId}
          titleOverride={"Field State:"}
          valueToStringFuncOverride={convertValueToFieldState}
          isBlinkingFunc={isFieldStateBlinking}
        />
        <SafeOverrideTooltip isOpen={isTooltipOpen} setIsOpen={setIsTooltipOpen} siteId={siteId} />
      </div>
      <SystemStatusIcons
        systemItems={[
          {
            tagName: TagsNames.AGGR_TRACKERS_STATUS,
            icon: AuxiliariesStatusIcons.Tracker,
            description: trackersIssuesDescription,
          },
          {
            tagName: TagsNames.AGGR_INVERTERS_STATUS,
            icon: AuxiliariesStatusIcons.Inverter,
            description: invertersIssusDescription,
          },
        ]}
      />
    </div>
  );
};
export default SystemStatus;
