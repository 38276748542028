import { NO_VALUE_PLACEHOLDER } from "../../common/ConstantValues";
import { ITagMetadata, TagSource, TagValueType } from "../data_point/models/TagsModels";
import {
  AgriSensorType,
  ISiteMetadata,
  ISitesMetadataDictionary,
  WeatherSensorFeatures,
} from "../sites/SiteModels";
import { getConverterFunction } from "./TagsToEnumTable";

const CommonAggregatedId = 32767;
const MaxHoursOfSun = 12;

export const invalidTagMetadata = {
  displayName: NO_VALUE_PLACEHOLDER,
  unitName: NO_VALUE_PLACEHOLDER,
  description: NO_VALUE_PLACEHOLDER,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  toStringConverter: (_) => NO_VALUE_PLACEHOLDER,
} as ITagMetadata;

class TagMetadata implements ITagMetadata {
  constructor(
    public uniqueName: string,
    public tagValueType: TagValueType,
    public unitName: string,
    public displayName: string,
    public description: string,
    public low: number,
    public high: number,
    public tagSource: TagSource,
    toStringConverter?: (value: number) => string
  ) {
    this.toStringConverter = toStringConverter ?? getConverterFunction(uniqueName);
  }
  toStringConverter: (value: number) => string;
  origin?: { siteName: string; tag: ITagMetadata };
}

export function getSiteTagsMetadata(smd: ISiteMetadata): ITagMetadata[] {
  const ret: ITagMetadata[] = [];

  const numberOfTrackers = Object.keys(smd.trackers).length;
  if (numberOfTrackers > 0) {
    ret.push(...generateCalculatedTrackerStateCountTagMetadata());
    ret.push(
      ...selectMany(
        Object.keys(smd.trackers).map((trackerId) => generateTrackerTagMetadata(parseInt(trackerId, 10)))
      )
    );
    ret.push(...generateCalculatedTrackerStatusTags(numberOfTrackers));
  }

  const numberOfPowerMetersDevices = Object.keys(smd.powerMeters).length;
  if (numberOfPowerMetersDevices > 0) {
    ret.push(
      ...selectMany(
        Object.keys(smd.powerMeters).map((deviceId) =>
          generatePowerMeterTagMetadata(parseInt(deviceId, 10), true)
        )
      )
    );
    //Hack aggragated
    ret.push(...generatePowerMeterTagMetadata(CommonAggregatedId, false));
    //prettier-ignore
    ret.push(new TagMetadata("VirtualTags:Portal:PowerMeter:AggrStatus", TagValueType.Enum, "",
      "Power Meter Status", "", 0, numberOfPowerMetersDevices, TagSource.Calculated));
  }

  const numberOfInverterDevices = Object.keys(smd.inverters).length;
  const aggregatedInverterPowerMax = roundUp(
    Object.values(smd.inverters).reduce((s, c) => s + c, 0) * 1.1
  );
  if (numberOfInverterDevices > 0) {
    ret.push(...generateInvertersTagMetadata(CommonAggregatedId, false, aggregatedInverterPowerMax));
    ret.push(
      ...selectMany(
        Object.entries(smd.inverters).map(([deviceId, dcCapacity]) =>
          generateInvertersTagMetadata(parseInt(deviceId, 10), true, roundUp(dcCapacity))
        )
      )
    );
    ret.push(...generateCalculatedInverterStatusTags(numberOfInverterDevices));
    ret.push(...generateCalculatedPowerTags(aggregatedInverterPowerMax * MaxHoursOfSun));
  }
  ret.push(...generateAlgoPerformanceTagMetadata(aggregatedInverterPowerMax));

  const numberOfWeatherDevices = Object.keys(smd.weatherSensors).length;
  if (numberOfWeatherDevices > 0) {
    //Total
    const allWeatherSensorFeatureFlags = Object.values(WeatherSensorFeatures).reduce(
      (agg: number, val) => agg | (val as number),
      0
    );
    ret.push(...generateWeatherTags(CommonAggregatedId, allWeatherSensorFeatureFlags, false));
    for (const [deviceId, deviceFeatures] of Object.entries(smd.weatherSensors)) {
      ret.push(...generateWeatherTags(parseInt(deviceId, 10), deviceFeatures, true));
    }
    ret.push(...generateCalculatedWeatherStatusTags(numberOfWeatherDevices));
  }

  if (smd.upsExists) {
    //prettier-ignore
    ret.push(new TagMetadata(`McsState:UpsEmergencyStatus:0`, TagValueType.Enum, "Undefined=0; Active=1; Inactive=2; Unknown=3;",
        "Ups Emergency Status", "", 0, 3, TagSource.Storage));
  }

  const numberOfAgriDevices = Object.keys(smd.agriSensors).length;
  if (numberOfAgriDevices > 0) {
    for (const agriDevice of new Set(Object.values(smd.agriSensors))) {
      generateTagsForAgriDevice(ret, CommonAggregatedId, agriDevice);
    }

    for (const [sensorId, sensorType] of Object.entries(smd.agriSensors)) {
      generateTagsForAgriDevice(ret, parseInt(sensorId, 10), sensorType);
    }
  }

  //prettier-ignore
  ret.push(new TagMetadata("Weather:EstimatedDni:0", TagValueType.Float, "kWh", "DNI", "Estimation of the DNI using Erbs algorithm", 0, 1500, TagSource.Storage));

  for (const id of smd.sprinklersIds) {
    ret.push(...generateFaucetOpeningRatioTagMetadata(id));
  }

  ret.push(...generateMcsTagMetadata());
  return ret;
}

export function addAliasedTags(sitesMetadata: ISitesMetadataDictionary): void {
  for (const [siteId, siteMetadata] of Object.entries(sitesMetadata)) {
    for (const [uniqueName, alias] of Object.entries(siteMetadata.tagAliases)) {
      const originSite = alias.siteName || siteId;
      const originTagMetadata = sitesMetadata[originSite]?.tags[alias.tagName];
      if (!originTagMetadata) {
        console.warn(`Could not find origin tag for alias ${uniqueName} in site ${siteId}`, {
          alias,
        });
        continue;
      }
      sitesMetadata[siteId].tags[uniqueName] = {
        ...originTagMetadata,
        uniqueName: uniqueName,
        origin: {
          siteName: originSite,
          tag: originTagMetadata,
        },
      };
    }
  }
}

const roundUp = (value: number, roundScale = 10) => value - (value % roundScale) + roundScale;

function selectMany<T>(arr: T[][]): T[] {
  return arr.reduce((a, b) => a.concat(b), []);
}

//prettier-ignore
const generateCalculatedTrackerStateCountTagMetadata = () => 
  [
    new TagMetadata("VirtualTags:Trackers:StateCount:Tracking", TagValueType.Int, "#", "Trackers tracking", "Trackers in Tracking State", 0,
      100, TagSource.Calculated),
    new TagMetadata("VirtualTags:Trackers:StateCount:Maintenance", TagValueType.Int, "#", "Trackers in maintenance",
      "Trackers in Maintenance State", 0, 100, TagSource.Calculated),
  ];

//prettier-ignore
const generateTrackerTagMetadata = (id: number) =>
  [
    new TagMetadata(`TrackerStatus:CurrentState:${id}`, TagValueType.Enum, "", "Field State", "", 0, 11, TagSource.Storage),
    new TagMetadata(`TrackerStatus:ShadingRatio:${id}`, TagValueType.Float, "", "Shading Ratio", "", 0, 100, TagSource.Storage),
    new TagMetadata(`TrackerStatus:CurrentElevation:${id}`, TagValueType.Float, "°", "Average tracker elevation", "Average angle of trackers", -75, 100, TagSource.Storage),
    new TagMetadata(`TrackerStatus:ErrorType:${id}`, TagValueType.Enum, "Enum", "Error flags", "", 0, 32, TagSource.Storage)
  ];

//prettier-ignore
const generateCalculatedTrackerStatusTags = (numberOfTrackers: number) => 
  [
    new TagMetadata("VirtualTags:Portal:TrackerStatus:SystemStatus", TagValueType.Enum, "", "Trackers status", "Status of the Site Trackers", 0,
      numberOfTrackers, TagSource.Calculated),
  ];

//prettier-ignore
const generateFaucetOpeningRatioTagMetadata = (id: number) =>
  [
    new TagMetadata(`CleaningFaucetOpeningRatio:OpeningRatio:${id}`, TagValueType.Float, "%", "Faucet Opening Ratio", "", 0,
      100, TagSource.Storage),
  ]

//prettier-ignore
const generatePowerMeterTagMetadata = (id: number, includeErrors: boolean) => {
  const metadataList =
    [
      new TagMetadata(`PowerMeter:ProductionTotal:${id}`, TagValueType.Float, "kWh", "Total Production", "",
        0, 1000, TagSource.Storage),
      new TagMetadata(`PowerMeter:ProductionDelta:${id}`, TagValueType.Float, "kWh", "Total Consumption", "",
        0, 10, TagSource.Storage)
    ];
  if (includeErrors) {
    metadataList.push(new TagMetadata(`PowerMeter:ErrorType:${id}`, TagValueType.Enum, "Enum", "Error flags", "",
      0, 0xffffffff, TagSource.Storage));
  }
  return metadataList;
}

//prettier-ignore
const generateInvertersTagMetadata = (id: number, includeErrors: boolean, maxValue: number) => {
  const metadataList =
    [
      new TagMetadata(`Inverter:AcPower:${id}`, TagValueType.Float, "kW", "AC Power",
        "Live Output Power of All the Inverters", 0, maxValue, TagSource.Storage),
      new TagMetadata(`Inverter:DcPower:${id}`, TagValueType.Float, "kW", "DC Power",
        "Live Input of All the Inverters", 0, maxValue, TagSource.Storage)
    ];
  if (includeErrors) {
    metadataList.push(new TagMetadata(`Inverter:ErrorType:${id}`, TagValueType.Enum, "Enum", "Error flags", "",
      0, 4294967300, TagSource.Storage));
  }
  return metadataList;
}

//prettier-ignore
const generateCalculatedInverterStatusTags = (maxLimit: number) =>
  [
    new TagMetadata("VirtualTags:Portal:Inverter:SystemStatus", TagValueType.Enum, "", "Inverters status", "Status of the Site Inverters", 0,
      maxLimit, TagSource.Calculated),
  ];

//prettier-ignore
const generateCalculatedPowerTags = (maxDailyPower: number) =>
  [
    new TagMetadata("VirtualTags:Portal:DailyProduction:AcPower", TagValueType.Float, "kWh", "AC Power",
      "Accumulated Daily Output of All Inverters", 0, maxDailyPower, TagSource.Calculated),
    new TagMetadata("VirtualTags:Portal:DailyProduction:DcPower", TagValueType.Float, "kWh", "DC Power",
      "Calcluated tag for daily spec", 0, maxDailyPower, TagSource.Calculated),
    new TagMetadata("VirtualTags:Portal:DailyProduction:Sp", TagValueType.Float, "kWh/kWp", "Specific Production",
      "Ratio of daily DC Power to installed panels' power", 0, 13, TagSource.Calculated),
    new TagMetadata("VirtualTags:Performance:PerformanceIndex", TagValueType.Float, "%", "Perf index",
      "Ratio of Actual DC Power to Potential DC Power", 0, 110, TagSource.Calculated),
  ];

//prettier-ignore
const generateWeatherTags = (id: number, flag: number, includeErrors: boolean) => {
  const ret: ITagMetadata[] = [];
  if ((flag & WeatherSensorFeatures.WindSpeed) != 0) {
    ret.push(new TagMetadata(`Weather:WindSpeed:${id}`, TagValueType.Float, "m/s", "Wind Speed",
      "Highest Wind Speed currently reported", 0, 20, TagSource.Storage));
  }

  if ((flag & WeatherSensorFeatures.WindDirection) != 0) {
    ret.push(new TagMetadata(`Weather:WindDirection:${id}`, TagValueType.Float, "°",
      "Wind Direction", "", 0, 360, TagSource.Storage));
  }

  if ((flag & WeatherSensorFeatures.Irradiation) != 0) {
    ret.push(new TagMetadata(`Weather:GlobalIrradiance:${id}`, TagValueType.Float, "w/m²", "GHI",
      "Global Horizontal Irradiance", 0, 1500, TagSource.Storage));
  }

  if ((flag & WeatherSensorFeatures.Temperature) != 0) {
    ret.push(new TagMetadata(`Weather:AmbientAirTemp:${id}`, TagValueType.Float, "°c",
      "Temperature", "Ambient Temperature", -50, 50, TagSource.Storage));
  }

  if ((flag & WeatherSensorFeatures.IrradiationInstalledOnTracker) != 0) {
    ret.push(new TagMetadata(`Weather:PlaneOfArrayIrradiance:${id}`, TagValueType.Float, "w/m²",
      "GTI", "", 0, 1500, TagSource.Storage));
  }

  if ((flag & WeatherSensorFeatures.TemperatureInstalledOnPanel) != 0) {
    ret.push(new TagMetadata(`Weather:BackOfModuleTemp:${id}`, TagValueType.Float, "°c",
      "Back Of Module Temp", "", -50, 50, TagSource.Storage));
  }

  if ((flag & WeatherSensorFeatures.RelativeHumidity) != 0) {
    ret.push(new TagMetadata(`Weather:RelativeHumidity:${id}`, TagValueType.Float, "%RH",
      "Relative Humidity", "", 0, 100, TagSource.Storage));
  }

  if ((flag & WeatherSensorFeatures.Barometric) != 0) {
    ret.push(new TagMetadata(`Weather:BarometricPressure:${id}`, TagValueType.Float, "%RH",
      "Barometric Pressure", "", 0, 100, TagSource.Storage));
  }

  if (includeErrors) {
    ret.push(new TagMetadata(`Weather:ErrorType:${id}`, TagValueType.Enum, "Enum",
      "Error flags", "", 0, 4294967300, TagSource.Storage));
  }

  return ret;
}

//prettier-ignore
const generateCalculatedWeatherStatusTags = (maxLimit: number) =>
  [
    new TagMetadata("VirtualTags:Portal:Weather:AggrStatus", TagValueType.Enum, "", "Weather Status", "", 0, maxLimit, TagSource.Calculated),
  ];

//prettier-ignore
const generateTagsForAgriDevice = (ret: ITagMetadata[], sensorId: number, sensorType: AgriSensorType) => {
  switch (sensorType) {
    case AgriSensorType.CwtSoil:
      ret.push(new TagMetadata(`Agri:SoilTemperature:${sensorId}`, TagValueType.Float, "Degrees",
        "Soil Temperature", "", 0, 100, TagSource.Storage));
      ret.push(new TagMetadata(`Agri:SoilHumidity:${sensorId}`, TagValueType.Float, " %RH",
        "Soil Humidity", "", 0, 100, TagSource.Storage));
      ret.push(new TagMetadata(`Agri:SoilPH:${sensorId}`, TagValueType.Float, "",
        "PH", "", 0, 100, TagSource.Storage));
      ret.push(new TagMetadata(`Agri:SoilConductivity:${sensorId}`, TagValueType.Float, "cm",
        "Soil Conductivity", "", 0, 20000, TagSource.Storage));
      ret.push(new TagMetadata(`Agri:SoilNitrogen:${sensorId}`, TagValueType.Float, "mg/kg(mg/L)",
        "Soil Nitrogen", "", 0, 3000, TagSource.Storage));
      ret.push(new TagMetadata(`Agri:SoilPotassium:${sensorId}`, TagValueType.Float, "mg/kg(mg/L)",
        "Soil Potassium", "", 0, 3000, TagSource.Storage));
      ret.push(new TagMetadata(`Agri:SoilPhosphorus:${sensorId}`, TagValueType.Float, "mg/kg(mg/L)",
        "Soil Phosphorus", "", 0, 3000, TagSource.Storage));
      break;
    case AgriSensorType.CwtPhotoSynth:
      ret.push(new TagMetadata(`Agri:PlantPhotosyntheticallyActiveRadiationPpfd:${sensorId}`, TagValueType.Float, "μmol/㎡",
        "Daily Photosynthetic Active Radiation", "", 0, 2000, TagSource.Storage));
      break;
    case AgriSensorType.HondeGrowthSensor:
      ret.push(new TagMetadata(`Agri:StemGrowth:${sensorId}`, TagValueType.Float, "mm",
        "Dendrometer(growth)", "", 0, 200, TagSource.Storage));
      break;
    case AgriSensorType.CwtRainGauge:
      ret.push(new TagMetadata(`Agri:RainFall:${sensorId}`, TagValueType.Float, "mm",
        "Rain Gauge", "", 0, 8, TagSource.Storage));
      break;
    default:
      throw new Error("received an invalid agri sensor");
  }
};

//prettier-ignore
const generateAlgoPerformanceTagMetadata = (aggregatedInverterPowerMax: number) => {
  return [
    new TagMetadata("VirtualTags:Portal:PerformanceModel:PotentialPowerProduction", TagValueType.Float, "kW",
      "Potential", "Potential Production Model", 0, aggregatedInverterPowerMax, TagSource.Calculated),
    new TagMetadata("VirtualTags:Portal:PerformanceModel:ContractPowerProduction", TagValueType.Float, "kW",
      "Contract", "", 0, aggregatedInverterPowerMax, TagSource.Calculated)
  ];
}

//prettier-ignore
const generateMcsTagMetadata = () =>
  [
    new TagMetadata("McsState:State:0", TagValueType.Enum, "", "Site State",
      "Undefined=0; Safety=1;Operational=2;", 0, 2, TagSource.Storage),
    new TagMetadata("McsState:EngineerEmergencyStatus:0", TagValueType.Enum, "", "Engineer Emergency Status",
      "Undefined=0; Active=1; Inactive=2; Unknown=3;", 0, 3, TagSource.Storage),
    new TagMetadata("McsState:CloudEmergencyStatus:0", TagValueType.Enum, "", "Cloud Emergency Status",
      "Undefined=0; Active=1; Inactive=2; Unknown=3;", 0, 3, TagSource.Storage),
    new TagMetadata("VirtualTags:Portal:System:FieldState", TagValueType.Enum, "", "Field State",
      "State of the whole Tracker Field", 0, 16, TagSource.Calculated),
    new TagMetadata("VirtualTags:Portal:System:SafetyReason", TagValueType.Int, "", "Safety Reason",
        "Reason that site is in Safe mode", 0, 31, TagSource.Calculated),
  ];
