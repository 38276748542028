import { FC } from "react";

import { useSelector } from "react-redux";

import getIcons from "../../SolarGikLib/icons/Icons";
import { IconCategory, AuxiliariesStatusIcons } from "../../SolarGikLib/icons/IconsModels";
import { RootState } from "../app/Store";
import { selectIsTagValid, selectSiteTag } from "../app/store/MultisiteTagsStore";
import classes from "./StatusIcon.module.css";

interface IStatusIconProps {
  tagName: string;
  icon: AuxiliariesStatusIcons;
  siteId: string;
}

enum Status {
  Undefined = 0,
  Ok = 1,
  Warning = 2,
  Error = 3,
}

const getStatusColor = (status: number) => {
  switch (status) {
    case Status.Ok:
      return "var(--ok-color)";
    case Status.Warning:
      return "var(--warning-color)";
    case Status.Error:
      return "var(--error-color)";
    case Status.Undefined:
      return "var(--undefined-color)";
    default:
      return "var(--undefined-color)";
  }
};

const ErorrIcon = getIcons(AuxiliariesStatusIcons.Error, IconCategory.Status);

const StatusIcon: FC<IStatusIconProps> = ({ tagName, icon, siteId }) => {
  const isTagValid = useSelector((state: RootState) => selectIsTagValid(state, siteId, tagName));
  const status = useSelector((state: RootState) => selectSiteTag(state, siteId, tagName).value);
  const Icon = getIcons(icon, IconCategory.Status);
  return (
    <>
      <Icon color={getStatusColor(status)} />
      {!isTagValid && <ErorrIcon className={classes["error-icon"]} />}
    </>
  );
};
export default StatusIcon;
