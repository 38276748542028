import { FC } from "react";

import { useSelector } from "react-redux";

import StatusIcon from "./StatusIcon";
import classes from "./SystemStatusIcons.module.css";
import { RootState } from "../app/Store";
import DataPointTooltip from "../data_point/wrapper/DataPointTooltip";
import { AuxiliariesStatusIcons } from "../../SolarGikLib/icons/IconsModels";

interface ISystemStatusIconsProps {
  systemItems: {
    tagName: string;
    icon: AuxiliariesStatusIcons;
    description: string;
  }[];
}
const SystemStatusIcons: FC<ISystemStatusIconsProps> = ({ systemItems }) => {
  const siteId = useSelector((state: RootState) => state.site.siteId);
  return (
    <ul className={classes["status-icons-list"]}>
      {systemItems.map((item) => {
        return (
          <div key={item.tagName} className={classes["container"]}>
            <DataPointTooltip
              tagName={item.tagName}
              isShowDateOnly={false}
              isShowTagMetadataOnly={false}
              siteId={siteId}
              descriptionOverride={item.description}
            >
              <StatusIcon tagName={item.tagName} siteId={siteId} icon={item.icon} />
            </DataPointTooltip>
          </div>
        );
      })}
    </ul>
  );
};

export default SystemStatusIcons;
