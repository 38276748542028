import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { ISolarSightApi } from "@solargik/solar-sight-webgl";
import classes from "./PixelsView.module.css";
import { AppDispatch, RootState } from "../../app/Store";
import { initializePixelsCanvas } from "./pixelsClientWrapper";
import { protectedResources } from "../../auth/authConfig";
import { acquireAccessToken } from "../../auth/authUtils";
import { getSolarSightProvider, SolarSightProvider } from "./solarSightProvider";
import { ReactComponent as LoadingIcon } from "./LoadingIcon.svg";

enum LoadingStatus {
  Loading = "loading",
  Loaded = "loaded",
}

interface IPixelViewProps {
  siteId: string;
}

export const PixelsView = ({ siteId }: IPixelViewProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const trackersStatus = useSelector(
    (state: RootState) => state.multiSitesTrackers.trackersStatus[siteId] || []
  );
  const selectedTrackersIds = useSelector(
    (state: RootState) => state.multiSitesTrackers.selectedTrackersIds
  );

  const solarSightProviderRef = useRef<SolarSightProvider | null>(null);
  const solarSightClientRef = useRef<ISolarSightApi | null>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const { instance } = useMsal();

  const loaderRef = useRef<HTMLDivElement>(null);
  const [loadState, setLoadState] = useState<LoadingStatus>(LoadingStatus.Loading);

  const fetchAccessToken = async () => {
    const scope = protectedResources.webAPI.scopes;
    const token = await acquireAccessToken(instance, scope);
    if (solarSightProviderRef.current) {
      solarSightProviderRef.current.userToken = token;
    }
  };

  const onLoadProgress = (progress: number) => {
    if (progress === 1) {
      setTimeout(() => {
        setLoadState(LoadingStatus.Loaded);
      }, 300);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    const solarSightProvider = getSolarSightProvider(dispatch);
    solarSightProviderRef.current = solarSightProvider;
    solarSightProviderRef.current.trackersStatus = trackersStatus;

    const intervalId = setInterval(async () => {
      await fetchAccessToken();
    }, 60 * 1000);

    const initializeCanvas = async () => {
      try {
        const pixelsClient = await initializePixelsCanvas(
          siteId,
          abortController.signal,
          solarSightProvider,
          onLoadProgress
        );
        solarSightClientRef.current = pixelsClient;
        solarSightClientRef.current?.setSelectedTrackersAsync(selectedTrackersIds);
      } catch (error) {
        setIsError(true);
        console.error(error);
      }
    };

    fetchAccessToken();
    initializeCanvas();

    return () => {
      abortController.abort();
      clearInterval(intervalId);
    };
  }, [instance, siteId]);

  useEffect(() => {
    if (solarSightProviderRef.current && trackersStatus.length > 0) {
      solarSightProviderRef.current.trackersStatus = trackersStatus;
    }
  }, [trackersStatus]);

  useEffect(() => {
    solarSightClientRef.current?.setSelectedTrackersAsync(selectedTrackersIds);
  }, [selectedTrackersIds]);

  return (
    <>
      {isError ? (
        <div className={classes["error-container"]}>
          <div className={classes["error-content"]}>
            <div className={classes["error-heading"]}>{"Oops!\n"}</div>
            {"Something Went Wrong.\nPlease Try Again"}
          </div>
        </div>
      ) : (
        <>
          <div
            ref={loaderRef}
            className={`${classes["loader-wrapper"]} ${
              loadState === LoadingStatus.Loaded ? classes["loader-hidden"] : ""
            }`}
          >
            <LoadingIcon />
          </div>
          <canvas
            className={`${classes["unity-canvas"]} ${
              loadState === LoadingStatus.Loaded ? classes["canvas-visible"] : ""
            }`}
            id="solar-sight-canvas"
            tabIndex={-1}
          />
        </>
      )}
    </>
  );
};
