import {IProgressHandler} from "../../global"
import {WrapperSolarSightProvider} from "./wrapperSolarSightProvider";
import UnityManager, {IUnityLoadingProvider, UnityInstance} from "../../unity/unityLoader";
import {SolarSightApi} from "./solarSightApi";
import {waitForAsync} from "./utils";
import {ISolarSightProvider} from "./iSolarSightProvider";
import {ISolarSightApi} from "./iSolarSightApi";
import {WebUserAppLifeCycle} from "./solarSightModels";

export class SolarSightLoader {
    private _config: any | null = null;
    private loadedScripts: Set<string> = new Set<string>();
    private _unityManager: UnityManager | null = null 
    
    private static loadingScripts: Set<string> = new Set<string>();

    constructor(private _solarSightUnityPrefixPath: string = "") {
    }

    /**
     * Loads the Unity binary files
     */
    public async loadSolarSightBinariesAsync(): Promise<void> {
        const prefixPath = `${this._solarSightUnityPrefixPath}/unity`;
        const streamingAssetsFolder = `${prefixPath}/StreamingAssets`;
        const buildFolder = `${prefixPath}/Build`;

        console.debug("Loading Unity build started...", { prefixPath, streamingAssetsFolder, buildFolder });

        const loaderUrl = `${buildFolder}/SolarSight.loader.js`;

        await this.loadScript(loaderUrl);
        console.log(">>>> Solar sight app version is:", (window as any).AppVersion);

        this._config = {
            arguments: [],
            dataUrl: `${buildFolder}/SolarSight.data.br`,
            frameworkUrl: `${buildFolder}/SolarSight.framework.js.br`,
            codeUrl: `${buildFolder}/SolarSight.wasm.br`,
            streamingAssetsUrl: streamingAssetsFolder,
            companyName: "SolarGik",
            productName: "SolarSight",
            productVersion: AppVersion,
            showBanner: false,
            cacheControl: (url: string): string => {
                if (url.match(/\.data/) || url.match(/\.bundle/)) {
                    return "must-revalidate";
                }
                return "no-store";
            },
        };
    }

    /**
     * Initializes the Unity instance. This method must be called after loadSolarSightBinariesAsync is done.
     * @param provider - ISolarSightProvider implementation to be used by SolarSight
     * @param onLoadProgress - Method for updating initialization progress. Notice: value range is 0->0.9 !!!
     */
    public async initSolarSightAsync(provider: ISolarSightProvider, onLoadProgress?: IProgressHandler)
        : Promise<ISolarSightApi>
    {
        if(!this._config)
        {
            throw new Error("Called initSolarSightAsync before build config was initiated! " +
                "Please call await loadSolarSightBinariesAsync before initSolarSightAsync")
        }

        console.info("initSolarSightAsync.start");

        const providerWrapper = new WrapperSolarSightProvider(provider);
        const unityLoadingProvider =
            new DefaultUnityLoadingHandler(
                onLoadProgress,
                provider.getCanvas()
            );
        unityLoadingProvider.solarSightUnityPrefixPath = this._solarSightUnityPrefixPath;

        const waitForDOMContentLoadedAsync = (): Promise<void> => {
            return new Promise<void>(() => {
                document.addEventListener('DOMContentLoaded', () => {
                    //just wait for the event
                });
            });
        };

        if (document.readyState === 'loading') {
            await waitForDOMContentLoadedAsync();
        }

        console.debug("initSolarSightAsync.waiting initUnityAsync (download .br)");
        this._unityManager = new UnityManager(unityLoadingProvider);
        const unityInstance = await this._unityManager.initUnityAsync(this._config);

        if(unityInstance === null) {
            providerWrapper.cleanup();
            throw new Error("Unity not initialized");
        }

        const iSolarSightApi = new SolarSightApi(unityInstance, providerWrapper);

        //Cleanup
        this._unityManager.addCleanUpDelegate(
            ()=>providerWrapper.cleanup());
        this._unityManager.addCleanUpDelegate(
            ()=>iSolarSightApi.cleanup());

        console.debug("initSolarSightAsync.waiting for unity to finish init (call smd)");
        await waitForAsync( () => providerWrapper.getAppState() === WebUserAppLifeCycle.AppLoadedReadyForSiteSelection);
        console.debug("initSolarSightAsync.done");
        return iSolarSightApi;
    };
    
    public unloadSolarSightInstance() {
        if(this._unityManager === null) {
            return;
        }
        this._unityManager.cleanup();
        this._unityManager = null;
    }
        

    private loadScript(src: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const existingScript = document.querySelector<HTMLScriptElement>(`script[src="${src}"]`);
            if (existingScript) {
                if(SolarSightLoader.loadingScripts.has(src))
                {
                    console.log(`script still loading: ${src}`)
                    existingScript.addEventListener('load', () => resolve());
                }
                else
                {
                    console.log(`Script already loaded: ${src}`);
                    resolve();
                }
                return;
            }

            // @ts-ignore
            const script: any = document.createElement<HTMLScriptElement>('script');
            script.src = src;
            script.async = true;

            script.onload = () => {
                SolarSightLoader.loadingScripts.delete(src);
                resolve();
            }
            script.onerror = () => reject(new Error(`Failed to load script: ${src}`));

            SolarSightLoader.loadingScripts.add(src);
            document.body.appendChild(script);
        });
    }
}



class DefaultUnityLoadingHandler implements IUnityLoadingProvider{
    constructor(
        public onLoadProgress: IProgressHandler 
            = function(fraction:number): void{ console.debug("Loading Value",fraction); }, 
        private _canvas?: HTMLCanvasElement) {}

    public solarSightUnityPrefixPath = "";

    public setSolarSightCanvas = (canvas: HTMLCanvasElement) => {
        this._canvas = canvas;
    }

    public getSolarSightCanvas = (): HTMLCanvasElement =>{
        if(!this._canvas)
        {
            throw new Error("No SolarSightCanvas provided!");
        }
        return this._canvas;
    }
}