import { ReactComponent as Inverter } from "./common/auxiliaries/InverterIcon.svg";
import { ReactComponent as Tracker } from "./common/auxiliaries/TrackerIcon.svg";
import { ReactComponent as DataPointErrorIcon } from "./common/error/DataPointErrorIcon.svg";
import { ReactComponent as EmergencyErrorHover } from "./common/error/emergency/EmergencyErrorHover.svg";
import { ReactComponent as EmergencyErrorIcon } from "./common/error/emergency/EmergencyErrorIcon.svg";
import { ReactComponent as HighSeverityError } from "./common/error/HighSeverityErrorIcon.svg";
import { ReactComponent as LowSeverityError } from "./common/error/LowSeverityErrorIcon.svg";
import { ReactComponent as MildSeverityError } from "./common/error/MildSeverityErrorIcon.svg";
import { ReactComponent as MultisiteHighSeverityError } from "./common/error/multisite/HighSeverityErrorIcon.svg";
import { ReactComponent as MultisiteLowSeverityError } from "./common/error/multisite/LowSeverityErrorIcon.svg";
import { ReactComponent as MultisiteMildSeverityError } from "./common/error/multisite/MildSeverityErrorIcon.svg";
import { ReactComponent as MultisiteUndefinedSeverityError } from "./common/error/multisite/UndefinedErrorIcon.svg";
import { ReactComponent as UndefinedSeverityError } from "./common/error/UndefinedErrorIcon.svg";
import { ReactComponent as ArrowDownDisabled } from "./common/fields/ArrowDownDisabledIcon.svg";
import { ReactComponent as FocusedArrowDown } from "./common/fields/ArrowDownFocusedIcon.svg";
import { ReactComponent as ArrowDown } from "./common/fields/ArrowDownIcon.svg";
import { ReactComponent as Calender } from "./common/fields/CalenderIcon.svg";
import { ReactComponent as ListItemSelected } from "./common/fields/ListItemSelectedIcon.svg";
import { ReactComponent as CloseIcon } from "./common/file-commands/CloseIcon.svg";
import { ReactComponent as Delete } from "./common/file-commands/DeleteIcon.svg";
import { ReactComponent as DeleteDisabled } from "./common/file-commands/DeleteDisabledIcon.svg";
import { ReactComponent as DownloadDisabled } from "./common/file-commands/DownloadDisabledIcon.svg";
import { ReactComponent as Download } from "./common/file-commands/DownloadIcon.svg";
import { ReactComponent as SendIcon } from "./common/file-commands/SendIcon.svg";
import { ReactComponent as UploadDisabled } from "./common/file-commands/UploadDisabledIcon.svg";
import { ReactComponent as Upload } from "./common/file-commands/UploadIcon.svg";
import { ReactComponent as WhiteSendIcon } from "./common/file-commands/WhiteSendIcon.svg";
import { ReactComponent as WhiteUpload } from "./common/file-commands/WhiteUploadIcon.svg";
import { ReactComponent as ChartLoader } from "./common/loaders/ChartsLoader.svg";
import { ReactComponent as ChartCardSkeleton } from "./common/loaders/ChartCardSkeleton.svg";
import { ReactComponent as Separator } from "./common/Separator.svg";
import { ReactComponent as Logo } from "./common/SolarGikLogo.svg";
import { ReactComponent as ACPower } from "./common/soma/agri/AcPowerIcon.svg";
import { ReactComponent as BarometricPressure } from "./common/soma/agri/BarometricPressureIcon.svg";
import { ReactComponent as Photosyn } from "./common/soma/agri/PhotosynIcon.svg";
import { ReactComponent as PlantGrowth } from "./common/soma/agri/PlantGrowthIcon.svg";
import { ReactComponent as RainGouge } from "./common/soma/agri/RainGougeIcon.svg";
import { ReactComponent as ShadingZeroTwentyFive } from "./common/soma/agri/shading0-25.svg";
import { ReactComponent as ShadingTwentyFiveFivty } from "./common/soma/agri/shading25-50.svg";
import { ReactComponent as ShadingFivtySeventyFive } from "./common/soma/agri/shading50-75.svg";
import { ReactComponent as ShadingSeventyFiveHundred } from "./common/soma/agri/shading75-100.svg";
import { ReactComponent as Soil } from "./common/soma/agri/SoilIcon.svg";
import { ReactComponent as ClosedEye } from "./common/soma/trends/ClosedEyeIcon.svg";
import { ReactComponent as EditLine } from "./common/soma/trends/EditLineIcon.svg";
import { ReactComponent as OpenedEye } from "./common/soma/trends/OpenedEyeIcon.svg";
import { ReactComponent as Plus } from "./common/soma/trends/PlusIcon.svg";
import { ReactComponent as UserRecordsButtonIcon } from "./common/soma/user-records/UserRecordIcon.svg";
import { ReactComponent as UserRecordsDisabledIcon } from "./common/soma/user-records/UserRecordsDisabledIcon.svg";
import { ReactComponent as Battery } from "./common/status/BatteryStatusIcon.svg";
import { ReactComponent as Grid } from "./common/status/GridStatusIcon.svg";
import { ReactComponent as BatteryInvalid } from "./common/status/invalid/BatteryStatusIcon.svg";
import { ReactComponent as GridInvalid } from "./common/status/invalid/GridStatusIcon.svg";
import { ReactComponent as StatusInverter } from "./common/status/InverterStatusIcon.svg";
import { ReactComponent as Error } from "./common/status/multisite/StatusError.svg";
import { ReactComponent as Ok } from "./common/status/multisite/StatusOk.svg";
import { ReactComponent as Undefined } from "./common/status/multisite/StatusUndefined.svg";
import { ReactComponent as Warning } from "./common/status/multisite/StatusWarning.svg";
import { ReactComponent as StatusMark } from "./common/status/StatusMarkIcon.svg";
import { ReactComponent as StatusTracker } from "./common/status/TrackerStatusIcon.svg";
import { ReactComponent as UserIcon } from "./common/UserIcon.svg";
import { ReactComponent as MaintenanceReason } from "./common/reason/Maintenance.svg";
import { ReactComponent as SnowReason } from "./common/reason/Snow.svg";
import { ReactComponent as SoftwareReason } from "./common/reason/Software.svg";
import { ReactComponent as SprinklersReason } from "./common/reason/Sprinklers.svg";
import { ReactComponent as TrackerReason } from "./common/reason/Tracker.svg";
import { ReactComponent as WindReason } from "./common/reason/Wind.svg";
import { ReactComponent as UpsReason } from "./common/reason/Ups.svg";
import { ReactComponent as UserReason } from "./common/reason/User.svg";
import { ReactComponent as EngineerReason } from "./common/reason/Engineer.svg";
import { ReactComponent as BarometricPressureInvalid } from "./common/soma/agri/invalid/BarometricPressureIcon.svg";
import { ReactComponent as SoilInvalid } from "./common/soma/agri/invalid/SoilIcon.svg";
import { ReactComponent as ACPowerInvalid } from "./common/soma/agri/invalid/AcPowerIcon.svg";
import { ReactComponent as RainGougeInvalid } from "./common/soma/agri/invalid/RainGougeIcon.svg";
import { ReactComponent as PhotosynInvalid } from "./common/soma/agri/invalid/PhotosynIcon.svg";
import { ReactComponent as PlantGrowthInvalid } from "./common/soma/agri/invalid/PlantGrowthIcon.svg";
import { ReactComponent as Connector } from "./common/soma/agri/Connector.svg";
import { ReactComponent as Clock } from "./common/soma/agri/ClockIcon.svg";
import { ReactComponent as WhiteDownload } from "./common/file-commands/WhiteDownload.svg";
import { IconCategory } from "./IconsModels";
import { ReactComponent as TrackerBattery } from "./common/TrackerBatteryIcon.svg";
import { ReactComponent as ExistsScheduledCommandIcon } from "./common/navigation/ScheduleCommand/ExistsScheduledCommandIcon.svg";
import { ReactComponent as RecurrenceIcon } from "./common/navigation/ScheduleCommand/Recurrence.svg";
import { ReactComponent as ActiveScheduledCommandIcon } from "./common/navigation/ScheduleCommand/ActiveScheduledCommandIcon.svg";
import { is150PercentScreen } from "../../common/WindowUtils";
import { ReactComponent as RightArrow } from "./common/RightArrow.svg";
import { ReactComponent as SprinklersIcon } from "./common/soma/sprinklers/SprinklerIcon.svg";
import { ReactComponent as DiffuseOptimizationOn } from "./common/diffuseOptimization/DiffuseOptimizationOn.svg";
import { ReactComponent as GridDelete } from "./common/grid/Delete.svg";
import { ReactComponent as GridEdit } from "./common/grid/Edit.svg";
import { ReactComponent as SafeOverridePopUpIcon } from "./common/status/SafeOverridePopUpIcon.svg";

interface IconSet {
  [key: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const getIcons = (iconName: string, iconCategory: IconCategory) => {
  const getIconsInner = (iconName: string, iconCategory: IconCategory) => {
    let icons: IconSet;
    switch (iconCategory) {
      case IconCategory.Reason:
        icons = getReasonIcons(is150PercentScreen());
        break;
      case IconCategory.SafetyReason:
        icons = getSafetyReasonIcons(is150PercentScreen());
        break;
      case IconCategory.Error:
        icons = getErrorIcons(is150PercentScreen());
        break;
      case IconCategory.Status:
        icons = getStatusIcons(is150PercentScreen());
        break;
      case IconCategory.FileCommands:
        icons = getFileCommandsIcons(is150PercentScreen());
        break;
      case IconCategory.Loaders:
        icons = getLoadersIcons();
        break;
      case IconCategory.Fields:
        icons = getFieldsIcons(is150PercentScreen());
        break;
      case IconCategory.Soma:
        icons = getSomaIcons(is150PercentScreen());
        break;
      case IconCategory.Auxiliaries:
        icons = getAuxiliariesIcons();
        break;
      case IconCategory.ScheduleCommand:
        icons = getScheduleCommandIcons(is150PercentScreen());
        break;
      case IconCategory.DiffuseOptimization:
        icons = getDiffuseOptimizationIcons(is150PercentScreen());
        break;
      case IconCategory.Grid:
        icons = getGridIcons(is150PercentScreen());
        break;
      default:
        icons = getGeneralIcons(is150PercentScreen());
        break;
    }
    if (!icons[iconName]) {
      console.error(`Icon ${iconName} not found: `);
    }
    return icons[iconName];
  };
  const getReasonIcons = (is150: boolean): IconSet => ({
    // 100 % icons overall size (width | height + padding) should be 32 * 32.
    Wind: (props) => (
      <WindReason
        width={is150 ? 16 : 24}
        height={is150 ? 16 : 24}
        style={{ padding: is150 ? 0 : 4 }}
        {...props}
      />
    ),
    Snow: (props) => (
      <SnowReason
        width={is150 ? 16 : 22}
        height={is150 ? 16 : 22}
        style={{ padding: is150 ? 0 : 5 }}
        {...props}
      />
    ),
    Sprinklers: (props) => (
      <SprinklersReason
        width={is150 ? 16 : 22}
        height={is150 ? 16 : 22}
        style={{ padding: is150 ? 0 : 5 }}
        {...props}
      />
    ),
    Tracker: (props) => (
      <TrackerReason
        width={is150 ? 16 : 24}
        height={is150 ? 16 : 24}
        style={{ padding: is150 ? 0 : 4 }}
        {...props}
      />
    ),
    Maintenance: (props) => (
      <MaintenanceReason
        width={is150 ? 16 : 22}
        height={is150 ? 16 : 22}
        style={{ padding: is150 ? 0 : 5 }}
        {...props}
      />
    ),
    Software: (props) => (
      <SoftwareReason
        width={is150 ? 16 : 24}
        height={is150 ? 16 : 24}
        style={{ padding: is150 ? 0 : 4 }}
        {...props}
      />
    ),
  });

  const getSafetyReasonIcons = (is150: boolean): IconSet => ({
    // 100 % icons overall size (width | height + padding) should be 32 * 32.
    Wind: (props) => (
      <WindReason
        width={is150 ? 16 : 18}
        height={is150 ? 16 : 18}
        style={{ padding: is150 ? 0 : 4 }}
        {...props}
      />
    ),
    Snow: (props) => (
      <SnowReason
        width={is150 ? 16 : 18}
        height={is150 ? 16 : 18}
        style={{ padding: is150 ? 0 : 5 }}
        {...props}
      />
    ),
    Ups: (props) => (
      <UpsReason
        width={is150 ? 16 : 14}
        height={is150 ? 16 : 14}
        style={{ padding: is150 ? 0 : 5 }}
        {...props}
      />
    ),
    Engineer: (props) => (
      <EngineerReason
        width={is150 ? 16 : 14}
        height={is150 ? 16 : 14}
        style={{ padding: is150 ? 0 : 5 }}
        {...props}
      />
    ),
    User: (props) => (
      <UserReason
        width={is150 ? 16 : 14}
        height={is150 ? 16 : 14}
        style={{ padding: is150 ? 0 : 5 }}
        {...props}
      />
    ),
  });

  const getAuxiliariesIcons = (): IconSet => ({
    Inverter: Inverter,
    Tracker: Tracker,
  });

  const getSomaIcons = (is150: boolean): IconSet => {
    const factor150 = 0.75;

    return {
      // agri data point
      ACPower: (props) => (
        <ACPower width={(is150 ? factor150 : 1) * 18} height={(is150 ? factor150 : 1) * 25} {...props} />
      ),
      ACPowerInvalid: (props) => (
        <ACPowerInvalid
          width={(is150 ? factor150 : 1) * 28}
          height={(is150 ? factor150 : 1) * 30}
          {...props}
        />
      ),
      BarometricPressure: (props) => (
        <BarometricPressure
          width={(is150 ? factor150 : 1) * 24}
          height={(is150 ? factor150 : 1) * 20}
          {...props}
        />
      ),
      BarometricPressureInvalid: (props) => (
        <BarometricPressureInvalid
          width={(is150 ? factor150 : 1) * 36}
          height={(is150 ? factor150 : 1) * 28}
          {...props}
        />
      ),
      Photosyn: (props) => (
        <Photosyn
          width={(is150 ? factor150 : 1) * 24}
          height={(is150 ? factor150 : 1) * 24}
          {...props}
        />
      ),
      PhotosynInvalid: (props) => (
        <PhotosynInvalid
          width={(is150 ? factor150 : 1) * 44}
          height={(is150 ? factor150 : 1) * 39}
          {...props}
        />
      ),
      PlantGrowth: (props) => (
        <PlantGrowth
          width={(is150 ? factor150 : 1) * 20}
          height={(is150 ? factor150 : 1) * 20}
          {...props}
        />
      ),
      PlantGrowthInvalid: (props) => (
        <PlantGrowthInvalid
          width={(is150 ? factor150 : 1) * 27}
          height={(is150 ? factor150 : 1) * 31}
          {...props}
        />
      ),
      RainGouge: (props) => (
        <RainGouge
          width={(is150 ? factor150 : 1) * 20}
          height={(is150 ? factor150 : 1) * 24}
          {...props}
        />
      ),
      RainGougeInvalid: (props) => (
        <RainGougeInvalid
          width={(is150 ? factor150 : 1) * 33}
          height={(is150 ? factor150 : 1) * 30}
          {...props}
        />
      ),
      Soil: (props) => (
        <Soil width={(is150 ? factor150 : 1) * 30} height={(is150 ? factor150 : 1) * 37} {...props} />
      ),
      SoilInvalid: (props) => (
        <SoilInvalid
          width={(is150 ? factor150 : 1) * 43}
          height={(is150 ? factor150 : 1) * 44}
          {...props}
        />
      ),

      // agri shading plan
      Shading025: (props) => (
        <ShadingZeroTwentyFive width={is150 ? 237 : 404} height={is150 ? 68 : 116} {...props} />
      ),
      Shading2550: (props) => (
        <ShadingTwentyFiveFivty width={is150 ? 237 : 404} height={is150 ? 68 : 116} {...props} />
      ),
      Shading5075: (props) => (
        <ShadingFivtySeventyFive width={is150 ? 237 : 404} height={is150 ? 68 : 116} {...props} />
      ),
      Shading75100: (props) => (
        <ShadingSeventyFiveHundred width={is150 ? 257 : 404} height={is150 ? 75 : 114} {...props} />
      ),
      Connector: (props) => (
        <Connector
          width={is150 ? 23 : 68}
          height={is150 ? 24 : 72}
          strokeWidth={is150 ? 5 : 1}
          {...props}
        />
      ),
      Clock: Clock,

      //sprinklers
      SprinklerOn: (props) => (
        <SprinklersIcon width={is150 ? 28 : 46} height={is150 ? 24 : 40} fill={"#7cdeaf"} {...props} />
      ),
      SprinklerOff: (props) => (
        <SprinklersIcon width={is150 ? 28 : 46} height={is150 ? 24 : 40} fill={"#d0d0d0"} {...props} />
      ),

      // trends-unresponsive
      ClosedEye: ClosedEye,
      OpenedEye: OpenedEye,

      // trends-responsive
      Plus: (props) => <Plus width={is150 ? 85 : 131} height={is150 ? 85 : 131} {...props} />,
      EditLine: (props) => (
        <EditLine
          width={is150 ? 16 : 24}
          height={is150 ? 16 : 24}
          style={{ padding: is150 ? 0 : 4 }}
          {...props}
        />
      ),
    };
  };

  const getLoadersIcons = (): IconSet => ({
    ChartLoader: ChartLoader,
    ChartCardSkeleton: ChartCardSkeleton,
  });

  const getFileCommandsIcons = (is150: boolean): IconSet => ({
    Download: Download,
    WhiteDownload: WhiteDownload,
    DownloadDisabled: DownloadDisabled,
    Upload: Upload,
    WhiteUpload: WhiteUpload,
    UploadDisabled: UploadDisabled,
    Delete: Delete,
    DeleteDisabled: DeleteDisabled,
    Send: SendIcon,
    WhiteSend: (props) => <WhiteSendIcon width={is150 ? 13 : 16} height={is150 ? 13 : 16} {...props} />,
    Close: CloseIcon,
  });

  const getFieldsIcons = (is150: boolean): IconSet => ({
    ListItemSelected: ListItemSelected,
    ArrowDown: (props) => <ArrowDown width={is150 ? 12 : 16} height={is150 ? 12 : 16} {...props} />,
    ArrowDownDisabled: (props) => (
      <ArrowDownDisabled width={is150 ? 12 : 16} height={is150 ? 12 : 16} {...props} />
    ),
    FocusedArrowDown: (props) => (
      <FocusedArrowDown width={is150 ? 12 : 16} height={is150 ? 12 : 16} {...props} />
    ),
    RightArrow: (props) => <RightArrow width={is150 ? 8 : 11} height={is150 ? 11 : 14} {...props} />,
    Calender: (props) => <Calender width={is150 ? 24 : 32} height={is150 ? 24 : 32} {...props} />,
  });

  const getGeneralIcons = (is150: boolean): IconSet => ({
    UserRecords: (props) => (
      <UserRecordsButtonIcon width={is150 ? 24 : 32} height={is150 ? 24 : 32} {...props} />
    ),
    UserRecordsDisabled: (props) => (
      <UserRecordsDisabledIcon width={is150 ? 24 : 32} height={is150 ? 24 : 32} {...props} />
    ),
    SolarGikLogo: (props) => <Logo width={90} height={24} {...props} />,
    UserIcon: (props) => <UserIcon width={is150 ? 22 : 30} height={is150 ? 22 : 30} {...props} />,
    Separator: (props) => <Separator width={is150 ? 18 : 26} height={2} {...props} />,
    TrackerBattery: TrackerBattery,
  });

  const getStatusIcons = (is150: boolean): IconSet => {
    const size100 = 64;
    const size150 = 45;
    return {
      //responsive
      Battery: (props) => (
        <Battery width={is150 ? size150 : size100} height={is150 ? size150 : size100} {...props} />
      ),
      BatteryInvalid: (props) => (
        <BatteryInvalid
          width={is150 ? size150 : size100}
          height={is150 ? size150 : size100}
          {...props}
        />
      ),
      Grid: (props) => (
        <Grid width={is150 ? size150 : size100} height={is150 ? size150 : size100} {...props} />
      ),
      GridInvalid: (props) => (
        <GridInvalid width={is150 ? size150 : size100} height={is150 ? size150 : size100} {...props} />
      ),
      Inverter: (props) => (
        <StatusInverter width={is150 ? 17 : 30} height={is150 ? 11 : 20} {...props} />
      ),
      Tracker: (props) => <StatusTracker width={is150 ? 21 : 38} height={is150 ? 10 : 19} {...props} />,
      StatusMark: (props) => (
        <StatusMark width={is150 ? size150 : size100} height={is150 ? size150 : size100} {...props} />
      ),
      SafeOverridePopUp: (props) => (
        <SafeOverridePopUpIcon width={is150 ? 9 : 14} height={is150 ? 9 : 14} {...props} />
      ),

      // unresponsive
      Ok: Ok,
      Warning: Warning,
      Error: Error,
      Undefined: Undefined,
    };
  };

  const getErrorIcons = (is150: boolean): IconSet => ({
    DataPointError: (props) => (
      <DataPointErrorIcon
        width={is150 ? 12 : 20}
        height={is150 ? 12 : 20}
        style={{ padding: is150 ? 2 : 6 }}
        {...props}
      />
    ),
    HighSeverityError: HighSeverityError,
    MildSeverityError: MildSeverityError,
    LowSeverityError: LowSeverityError,
    UndefinedSeverityError: UndefinedSeverityError,
    MultisiteHighSeverityError: MultisiteHighSeverityError,
    MultisiteMildSeverityError: MultisiteMildSeverityError,
    MultisiteLowSeverityError: MultisiteLowSeverityError,
    MultisiteUndefinedSeverityError: MultisiteUndefinedSeverityError,
    EmergencyError: EmergencyErrorIcon,
    EmergencyErrorHover: EmergencyErrorHover,
  });
  const getScheduleCommandIcons = (is150: boolean): IconSet => ({
    ExistsScheduledCommand: (props) => (
      <ExistsScheduledCommandIcon width={is150 ? 24 : 37} height={is150 ? 24 : 37} {...props} />
    ),
    Recurrence: (props) => <RecurrenceIcon width={15} height={15} {...props} />,
    ActiveScheduledCommand: (props) => <ActiveScheduledCommandIcon width={20} height={14} {...props} />,
  });

  const getDiffuseOptimizationIcons = (is150: boolean): IconSet => ({
    DiffuseOptimizationOn: (props) => (
      <DiffuseOptimizationOn width={is150 ? 29 : 46} height={is150 ? 28 : 45} {...props} />
    ),
  });

  const getGridIcons = (is150: boolean): IconSet => ({
    Delete: (props) => <GridDelete width={is150 ? 14 : 16} height={is150 ? 14 : 16} {...props} />,
    Edit: (props) => <GridEdit width={is150 ? 14 : 18} height={is150 ? 14 : 18} {...props} />,
  });

  return getIconsInner(iconName, iconCategory);
};

export default getIcons;
