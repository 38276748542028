import { useMsal } from "@azure/msal-react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useSelector } from "react-redux";

import classes from "./Header.module.css";
import HeaderDataPoints from "./HeaderDataPoints";
import SystemStatus from "./SystemStatus";
import getIcons from "../../../SolarGikLib/icons/Icons";
import { GeneralIcons, IconCategory } from "../../../SolarGikLib/icons/IconsModels";
import { RootState } from "../../app/Store";
import Emergency from "../../banner/emergency/Emergency";
import TimeComponent from "../../banner/TimeComponent";
import UserOptionDropdownMenu from "../../banner/UserOptionDropdownMenu";
import InteractiveSolargikLogo from "../InteractiveSolargikLogo";
import SwitchSiteComponent from "../SwitchSiteComponent";
import ExistsScheduledCommandPopOver from "../../trackers/ScheduleCommand/ExistsScheduledCommandPopOver";
import { FeatureFlag } from "../../featureFlags/FeatureFlagsModels";
import DiffuseOptimizationStatus from "../../diffuseOptimization/DiffuseOptimizationStatus";

export default function Header() {
  const { instance } = useMsal();
  const siteId = useSelector((state: RootState) => state.site.siteId);
  const isScheduledMaintenanceEnabled = useSelector((state: RootState) =>
    state.featureFlags.sitesFeatureFlags[siteId].includes(FeatureFlag.ScheduledMaintenance)
  );

  const UserIcon = getIcons(GeneralIcons.UserIcon, IconCategory.General);
  return (
    <AppBar position="relative" className={classes["app-bar"]}>
      <Toolbar className={classes["tool-bar"]}>
        <div className={`${classes.container} ${classes["left-components"]}`}>
          <div className={`${classes.container} ${classes["logo"]}`}>
            <InteractiveSolargikLogo />
          </div>
          <div className={classes["sites-dropdown"]}>
            <SwitchSiteComponent />
          </div>
          <div className={`${classes.container} ${classes["data-points"]}`}>
            <HeaderDataPoints siteId={siteId} />
          </div>
        </div>
        <div className={`${classes.container} ${classes["right-components"]}`}>
          <DiffuseOptimizationStatus siteId={siteId} />
          <div className={`${classes.container} ${classes["system-status"]}`}>
            <SystemStatus siteId={siteId} />
          </div>
          <div className={classes["emergency"]}>
            <Emergency />
          </div>
          {isScheduledMaintenanceEnabled && <ExistsScheduledCommandPopOver siteId={siteId} />}
          <div className={`${classes.container} ${classes["time-and-user"]}`}>
            <TimeComponent />
            <UserOptionDropdownMenu instance={instance} icon={UserIcon} />
          </div>
        </div>
      </Toolbar>
    </AppBar>
  );
}
