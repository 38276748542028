import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import SolarGikDropdown, { MenuItem } from "../../SolarGikLib/fields/Dropdown";
import getIcons from "../../SolarGikLib/icons/Icons";
import { FieldIcons, IconCategory } from "../../SolarGikLib/icons/IconsModels";
import { RootState } from "../app/Store";
import SiteNameComponent from "../siteName/SiteNameComponent";
import { selectSiteId } from "../sites/SiteStore";
import { resetSiteSpecificData } from "../app/store/GlobalActions";

const SwitchSiteComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const onSiteNameSubmitHandler = (siteId: string) => {
    dispatch(resetSiteSpecificData());
    const currentPathName = location.pathname.split("/").slice(2).join("/");
    navigate(`/${siteId}/${currentPathName}`);
  };

  const sitesIds = useSelector((state: RootState) => state.user.siteIds);
  const currentSiteId = useSelector(selectSiteId);

  const menuItems: MenuItem[] = sitesIds.map((siteId) => {
    return {
      label: <SiteNameComponent siteId={siteId} />,
      onClick: () => onSiteNameSubmitHandler(siteId),
      disabled: siteId == currentSiteId,
    };
  });

  const ArrowDown = getIcons(FieldIcons.ArrowDown, IconCategory.Fields);
  return (
    <div>
      {menuItems && (
        <SolarGikDropdown
          icon={ArrowDown}
          menuItems={menuItems}
          title={<SiteNameComponent siteId={currentSiteId} asTitle={true} />}
        />
      )}
    </div>
  );
};
export default SwitchSiteComponent;
